var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-content"},[_c('div',{staticClass:"container-fluid"},[_c('Breadcrumb',{attrs:{"pgTitle":_vm.$t('nav.messages'),"pgIcon":'bx bx-envelope',"refs":_vm.refs,"addNew":_vm.addNew}}),_c('DataTable',{attrs:{"refs":_vm.refs,"colspan":6,"hasSearchFilter":true,"hasDateFilter":true,"hasStatusFilter":true,"hasLecturerFilter":true,"hasHashId":true,"hasMultiSelect":false,"hasBulkAction":true,"HasExport":true,"hasShowEntries":true,"lblSearch":_vm.$t('app.search'),"fields":{
                'id': 'id',
                'name': 'name',
                'email': 'email',
                'body': 'body',
                'date': 'date'
            },"hasName":true,"name":_vm.$t('view.name'),"hasEmail":true,"email":_vm.$t('view.email'),"hasDate":true,"hasSeen":true,"hasStatus2":true,"hasShowEntry":true,"hasActions":true,"hasDeleteButton":true,"actionable":true,"sorDataTable":true},on:{"hasAddNewPermission":_vm.handlePermissoinChange}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }